<template>
  <div class="product-badges z-10"
    :class="{ 'absolute top-[10px] left-[10px]  md:p-[10px]': absolute, 'max-w-[112px]': !absolute }">
    <div v-if="isOnlineOnly"
      class="product-badge product-badge--new uppercase mb-[10px] bg-black rounded-full text-white px-4 py-[2px] font-alt text-center"
      :class="{ 'text-sm': absolute, 'text-xs': !absolute }">
      Online Only
    </div>

    <div v-if="isNewArrival"
      class="product-badge product-badge--sale uppercase mb-[10px] text-center bg-[#FBCFE8] text-black px-4 py-[2px] rounded-full font-alt"
      :class="{ 'text-sm': absolute, 'text-xs': !absolute }">
      New Arrival
    </div>

    <div v-if="isStaffPick"
      class="product-badge product-badge--sale uppercase mb-[10px] text-center bg-[#179DAB] text-black px-4 py-[2px] rounded-full text-white font-alt"
      :class="{ 'text-sm': absolute, 'text-xs': !absolute }">
      Staff Pick
    </div>

    <div v-if="isFreshBatch"
      class="product-badge product-badge--sale uppercase mb-[10px] text-center bg-[#25C9D0] text-white px-4 py-[2px] rounded-full text-white font-alt"
      :class="{ 'text-sm': absolute, 'text-xs': !absolute }">
      Fresh Batch
    </div>
  </div>
</template>

<script setup lang="ts">
const props = defineProps<{
  tags: string[]
  absolute: boolean
}>()

const isOnlineOnly = computed(() => props.tags.includes('Online Only'))
const isNewArrival = computed(() => props.tags.includes('New Arrival'))
const isStaffPick = computed(() => props.tags.includes('Staff Pick'))
const isFreshBatch = computed(() => props.tags.includes('Fresh Batch'))
</script>
